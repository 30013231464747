import React from 'react'
import Imgcard from './Components/ImgCard';
import {  Grid } from '@mui/material'; 
import pic1 from '../../../../assets/Images/im1.png'
import pic2 from '../../../../assets/Images/im2.png' 
import pic3 from '../../../../assets/Images/im3.png'
import pic4 from '../../../../assets/Images/im4.png' 
import pic5 from '../../../../assets/Images/im5.png' 
import pic6 from '../../../../assets/Images/im6.png' 
import pic7 from '../../../../assets/Images/im7.png'
import styled from '@emotion/styled';
import Regtxt from '../../Regtxt';


const MainHeader = styled.div`
  min-height: auto; 
  display:flex;  
  flex-direction: column;
  justify-content:center; 
  align-items:center; 
  gap:70px; 
  margin: 200px 0px;
  @media(max-width: 599px){
    padding: 0 20px;
  }
`
const Feature = () => { 


    
  return ( 
    <MainHeader id="feature"> 
       <Regtxt textAlign="center">The Future Of Real Estate Buying</Regtxt>
    <Grid container  gap="20px" justifyContent="center" >   
       <Grid item xs={12} sm={5.5} md={3.2} lg={2.2}> 
         <Imgcard BG={pic1} height="460px" p1="Azizi Venice" p2="Dubai South, Dubai, UAE" />  
       </Grid> 
      <Grid item container spacing={2} xs={12} sm={5.5} md={3.2} lg={2.2} > 
       <Grid item xs={12} lg={12}> 
         <Imgcard BG={pic2} height="200px" p1="LIV Waterside" p2="Marsa Dubai" />  
       </Grid> 
       <Grid item xs={12} lg={12}> 
         <Imgcard height="200px" BG={pic3} p1="Utopia at Damac Hills" p2="Dubai" />  
       </Grid>
      </Grid> 
      <Grid item xs={12} sm={5.5} md={3.2} lg={2.2}> 
         <Imgcard BG={pic4} height="460px" p1="Nobles Tower" p2="Business Bay, Dubai"/>  
       </Grid> 
       <Grid item container spacing={2} xs={12} sm={5.5} md={3.2} lg={2.2} > 
        <Grid item xs={12}  lg={12}> 
          <Imgcard BG={pic5} height="200px" p1="Eleganz" p2="Jumeirah Village Circle, Dubai" />  
        </Grid> 
        <Grid item xs={12} lg={12}> 
          <Imgcard height="200px" BG={pic6} p1="Duplex" p2="Jumeirah Village Circle, Dubai" />  
        </Grid> 
      </Grid>  
      <Grid item xs={12} sm={5.5} md={3.2} lg={2.2}> 
         <Imgcard BG={pic7} height="460px" p1="310 Riverside Crescent" p2="Sobha Hartland II, Dubai" />  
       </Grid> 
    </Grid> 
    </MainHeader>
  )
}

export default Feature;