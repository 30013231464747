import { Typography } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';

const StyledPara = styled(Typography)`
    @media(max-width: 599px){
        font-size: ${(props)=>props.fs599 };
    }
`

const Regtxt = (props) => {
    const {children}=props;
    return (
        <StyledPara fontSize="clamp(2rem, 2.25vw + 1.55rem, 4.25rem)" lineHeight="1.1" color="white" fontFamily="AvR"   textAlign="left" my={2} sx={{marginRight:{xs:"0px", md:"10px"},lineHeight:"1.3"}} {...props}>
            {children}
        </StyledPara>
    );
}
 
export default Regtxt;