import React from "react";
import { Grid, Link, Container, Box } from "@mui/material";
import Paragraph from "../../Paragraph";
import logo from "../../../../assets/Images/logo.png";
import x from "../../../../assets/Images/x.png";
import tg from "../../../../assets/Images/tg.png";
import styled from "@emotion/styled";
import { Blackbg } from "../../../Styles/Maintext.styles";
import Maintxt from "../../Maintxt";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BlueButton from "../../BlueButton";

const MainIconImg2 = styled.img`
  width: ${props=>props.w? props.w :"160px"};
  height: auto;
`;

const WhiteLink = styled(Link)`
  text-decoration: none;
  color: white;
`;
const Summary = () => {
  const navLinks = [
  { title: "About", link: "#about" },
  // { title: "Features", link: "#features" },
  { title: "Ecosystem", link: "#ecosystem" },
  { title: "Token", link: "#tokenomics" },
  // { title: "Blog", link: "#blog"},
  ]; 
  return (
    <><Box  bgcolor="#171719" boxShadow="0px -10px 100px #0066FF" padding="20px 0">

      <Blackbg noht noclr>
        <Container
          maxWidth="xl"
          sx={{ paddingTop: "50px", paddingBottom: "50px"}}
        >
          <Grid container display="flex" flexDirection={{xs : "column", lg:"row"}} justifyContent="space-between" gap="20px 0" alignItems={{xs:'start'}}>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap:"10px",
                alignItems: {
                  xs: "start",
                  md: "start",
                  marginTop: { xs: "20px", md: "0px" },
                },
              }}
            >
              <Link
                href="/"
                underline="none"
                color="inherit"
                display="flex"
                alignItems={{xs:'start',md:'center'}}
                gap={1}
              >
                <MainIconImg2 src={logo} alt="Brick Block" />
              </Link>
              <Paragraph
                color="#A8A8A8"
                maxWidth="250px"
                sx={{
                  textAlign: { xs: "start", md: "start" },
                }}
              >
                Real estate investment powered by blockchain for fractional ownership and passive income.
              </Paragraph>
              <Paragraph
                color="#A8A8A8"
                variant="body2" 
                maxWidth="250px"
                sx={{
                  textAlign: { xs:"start", md: "start" },
                }}
              >
               Email: info@brickblock.estate
              </Paragraph>
              <BlueButton href="https://app.uniswap.org/swap?outputCurrency=0x00199C511DC889B8155fa425fc0363Ed481e8f48&chain=ethereum" target="blank" >Buy Now<ArrowForwardIcon /></BlueButton>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap:"5px",
                alignItems: {
                  xs: "start",
                  md: "start",
                  marginTop: { xs: "20px", md: "0px" },
                },
              }}
            >
              <Maintxt variant="h6" color="#fff">Quick Links</Maintxt>
              {navLinks.map((item) => (
                <Link
                  href={item.link}
                  underline="none"
                  color="inherit"
                  key={item.title}
                >
                  <Paragraph  marginTop="0px" marginBottom="0px">
                    {item.title}
                  </Paragraph>
                </Link>
              ))}
            </Grid>  
            {/* <Grid
              item
              xs={12}
              sm={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap:"5px",
                alignItems: {
                  xs: "start",
                  md: "start",
                  marginTop: { xs: "20px", md: "0px" },
                },
              }}
            >
              <Maintxt variant="h6" color="#fff">Documentation</Maintxt>
              {DocLinks.map((item) => (
                <Link
                  href={item.link} 
                  target="_blank"
                  underline="none"
                  color="inherit"
                  key={item.title}
                >
                  <Paragraph fontFamily="OpenSans" marginTop="0px" marginBottom="0px">
                    {item.title}
                  </Paragraph>
                </Link>
              ))}
            </Grid> */}
            <Grid
              container
              item
              xs={12}
              sm={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "start", md: "start" },
                marginTop: { xs: "20px", md: "0px" },
              }}
            >
              <Grid item>
                <Maintxt variant="h6"  color="#fff">Socials</Maintxt>
              </Grid>
              <Grid item display="flex"  gap="15px">
                <WhiteLink href="https://twitter.com/brickblockETH" target="blank">
                  <MainIconImg2  w="35px" src={x} alt="Twitter" />
                </WhiteLink>
                <WhiteLink href="https://t.me/brickblockETH" target="blank">
                  <MainIconImg2  w="35px" src={tg} alt="Telegram" />
                </WhiteLink>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Blackbg>
          </Box>
    </>
  );
};

export default Summary;
