import React from 'react'
import styled from '@emotion/styled'  
import Paragraph from '../../../../Paragraph';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Typography } from '@mui/material';


const Imgcard = (props) => { 
    
   const Imgbg = styled.div`
    overflow: hidden;
    position:relative;
    background-image: url(${props.BG});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: end;
    justify-content: start;
    width:calc(100% - 40px); 
    min-height:${props=> props.height  ?props.height :  "200px"}; 
    border-radius:5px;
    padding: 20px;
` 

  return ( 
    <Imgbg height={props.height}>  
      <Typography variant='h5' fontFamily="AvR" color="white" sx={{maxWidth:"100%",padding:"10px 0",opacity:".5",position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}>   
         PLACEHOLDER
      </Typography>  
       <Box > 
        <Paragraph variant="h6">{props.p1}</Paragraph> 
        <Paragraph  variant="body2" sx={{display:'flex', justifyContent:'start',alignitems:'center'}}><LocationOnIcon sx={{color:"white",fontSize:"16px"}}/>{props.p2}</Paragraph>
       </Box>
    </Imgbg>
  )
}

export default Imgcard;