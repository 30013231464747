import logo from './logo.svg';
import './App.css';
import Main from './Views/Pages/BrickBlock';


function App() { 
  return (
    <div className="App"><Main /></div>
  );
}

export default App;
