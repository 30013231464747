import React, { useEffect, useState } from 'react'; 
import { Box, Container, Grid } from '@mui/material';
import Paragraph from '../../Paragraph'
import Doubletxt from '../../Doubletxt' 
import topimg from '../../../../assets/Images/token.png';  
import blue from '../../../../assets/Images/blue.png';  
import FileCopyIcon from '@mui/icons-material/FileCopy';
import BlueButton from '../../BlueButton'; 
import LaunchIcon from '@mui/icons-material/Launch'; 
import Regtxt from '../../Regtxt';
import TaskIcon from '@mui/icons-material/Task';

const Tokenomics = () => { 

    const [contract,setContract] = useState("0x00199C511DC889B8155fa425fc0363Ed481e8f48")
    const [lP,setLP] = useState("")
    const [fDV,setFDV] = useState("")
    const [isCopied,setisCopied] = useState(false)

    const  handleCopy = () =>{
      navigator.clipboard.writeText(contract)
      setisCopied(true)
      setTimeout(()=>{
        setisCopied(false)
      },3000)
    }

    function formatNumber(num) {
      if (num >= 1e9) {
        return (num / 1e9).toFixed(1) + 'B';
      } else if (num >= 1e6) {
        return (num / 1e6).toFixed(1) + 'M';
      } else if (num >= 1e3) {
        return (num / 1e3).toFixed(1) + 'K';
      } else {
        return num?.toString();
      }
    }
    const fetchTokenPriceInUSDT = async () => {
      try {
        const response = await fetch(`https://api.dexscreener.com/latest/dex/pairs/ethereum/0x6a63dB5bb12f58CDB1fD95095c64642ce957dFd4`)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json()
        setLP( () => formatNumber( result?.pair?.liquidity?.usd ) )
        setFDV( () => formatNumber( result?.pair?.fdv ) )
      } catch (error) {
        console.error('Price error: ', error);
      }
    }

    useEffect(()=>{
      fetchTokenPriceInUSDT()
      const interval = setInterval(() => {
        fetchTokenPriceInUSDT()
      }, 15000) // 1 minute interval
  
      return () => clearInterval(interval) // Cleanup on component unmount
    },[])

  return (<Box position="relative" marginTop="300px">

    <img Width="100%" src={blue} alt='' style={{ maxWidth:'1000px',position:"absolute",top:"-300px",left:"50%", transform:"translate(-50%,0)" ,zIndex:0}}  />
    <Container   
      id="tokenomics"  
      maxWidth="xl"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "100px",
        paddingTop: { xs: "150px", md: "150px" },
        background:"#070707", 
        marginTop :"180px",
        marginBottom :"180px",
        borderRadius:"10px",
        position:"relative"
      }}
      > 
      <img Width="180px" src={topimg} alt='brickblock' style={{ maxWidth:'100%',position:"absolute",top:"-100px",left:"50%", transform:"translate(-50%,0)" }}  />
      <Regtxt
        textAlign="center"
        my="20px" 
        sx={{ marginTop: "0" }}
      >
        $BRICK Token Powers <br /> Our Ecosystem
      </Regtxt>
      <Grid
        container
        marginTop="70px"
        justifyContent="center"
        gap={2}
        alignItems="center" 
      >
        <Grid data-aos="fade-up" borderRadius="8px" padding="50px 20px" gap="20px 0" container item xs={12} md={11} sx={{backgroundColor:"#0066FF"}} justifyContent="space-evenly">
          <Doubletxt xs={12} sm={6} def1="Total Supply" def2="100,000,000" />
          <Doubletxt xs={12} sm={6} def1="Buy/Sell Tax" def2="5/5" />
        </Grid> 
        <Grid data-aos="fade-up" borderRadius="8px" gap="20px 0" container item xs={12} md={11} sx={{backgroundColor:"#171719",padding:{xs:"50px 20px",sm:"50px 30px"}}} justifyContent="space-evenly">
          <Doubletxt def1="Liquidity" def2={`${lP} USD`} />
          <Doubletxt def1="FDV/MC" def2={`${fDV} USD`} />
        </Grid>
   
        <Grid data-aos="fade-up" borderRadius="8px" 
          item
          md={11}
          xs={12}
          display="flex"
          justifyContent="space-between"  
          textAlign="center"
          gap="20px 0"
          sx={{
            flexDirection:{xs:"column",sm:"row"},
            background:
              "#171719",
              padding:{xs:"50px 20px",sm:"50px 30px"}
          }}
        >
          <Paragraph fontFamily="RSb" my="0px">
            Contract address:
          </Paragraph>
          <Paragraph variant="body2" fontFamily="PopR" my="0px" textAlign="right" sx={{wordBreak:"break-all", display:"flex", justifyContent:"center", alignItems:"center",gap:"10px"}}>
           {contract} {isCopied ? <TaskIcon  onClick={handleCopy}   sx={{cursor:"pointer",fontSize:"16px",color:"white"}} />: <FileCopyIcon onClick={handleCopy}  sx={{cursor:"pointer",fontSize:"16px",color:"white"}} />}
          </Paragraph>
        </Grid>  
        <Grid
          item
          md={11}
          xs={12}
          borderRadius="5px"
          display="flex"
          justifyContent="space-between"  
          textAlign="center"
          gap="10px"
          // px={3}
          py={1}
          sx={{
           
          }}
        >
         <Box display="flex" data-aos="fade-up" justifyContent="start"  flexWrap="wrap"  alignitems="center" gap="20px"> 
              <BlueButton   sx={{border:"2px solid #181717 !important", borderRadius:"5px !important",display:"flex", gap:"10px", backgroundColor:"#070707 !important"}} href="https://etherscan.io/address/0x00199C511DC889B8155fa425fc0363Ed481e8f48" target="blank" >View Contract  <LaunchIcon sx={{color:"white","hover":'color:"#0066FF"'}} /></BlueButton>
              <BlueButton   sx={{border:"2px solid #181717 !important", borderRadius:"5px !important",display:"flex", gap:"10px", backgroundColor:"#070707 !important"}} href="https://dexscreener.com/ethereum/0x00199C511DC889B8155fa425fc0363Ed481e8f48" target="blank" >Dexscreener<LaunchIcon sx={{color:"white","hover":'color:"#0066FF"'}} /></BlueButton> 
              <BlueButton   sx={{border:"2px solid #181717 !important", borderRadius:"5px !important",display:"flex", gap:"10px", backgroundColor:"#070707 !important"}} href="https://www.dextools.io/app/en/ether/pair-explorer/0x6a63db5bb12f58cdb1fd95095c64642ce957dfd4?t=1715499190081" target="blank" >Dextools<LaunchIcon sx={{color:"white","hover":'color:"#0066FF"'}} /></BlueButton> 
              <BlueButton   sx={{border:"2px solid #181717 !important", borderRadius:"5px !important",display:"flex", gap:"10px", backgroundColor:"#070707 !important"}} href="https://brickblock.gitbook.io/brickblock-whitepaper" target="blank" >Whitepaper <LaunchIcon sx={{color:"white","hover":'color:"#0066FF"'}} /></BlueButton>
         </Box>
        </Grid> 
         
      </Grid>
    </Container>
          </Box>
  )
}

export default Tokenomics;