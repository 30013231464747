import { Box, Grid } from '@mui/material';
import React from 'react';
import BlueButton from '../../../../BlueButton';
import Paragraph from '../../../../Paragraph'; 
import styled from '@emotion/styled';
 
const Img = styled.img`
width:${(props) => props.w ? props.w : "100%"};
height: ${(props) => props.h ? props.h : "auto"};
padding-top: ${(props) => props.ptop ? props.ptop : "0px"}; 
`
const   BlogCard = (props) => {
    return (
        <Grid item xs={12} sm={5.7} lg={3.8} display="flex"  sx={{paddingTop:{xs:"0px", md:props.ptop}}} flexDirection="column" position="relative" borderRadius="8px" bgcolor={props.bg? props.bg: "#171719"} minHeight="750px" paddingBottom="10px "  {...props}> 
            <Box  >
                <Img w="100%" src={props.psrc} alt="pic" paddingLeft="" />
                <Paragraph variant="body2" my="0px" marginLeft="25px" fontFamily="AvR">{props.time}</Paragraph>   
            </Box>
            <Box display="flex"  flexDirection="column" gap="10px" padding={props.padd ? props.padd:"20px"}>
                <Paragraph variant="h4" color="white" fontFamily="AvR" >{props.def1}</Paragraph>
                <Paragraph variant="body2" my="0px" >{props.def2}</Paragraph>    
                <BlueButton sx={{border:"2px solid #707070 !important", borderRadius:"5px !important", backgroundColor:"#171719 !important"}}>View Blog </BlueButton>
            </Box>
 
        </Grid>
      );
}
 
export default BlogCard;