import { Grid } from '@mui/material';
import React from 'react';
import Maintxt from '../Maintxt';
import Paragraph from '../Paragraph';
const Doubletxt = (props) => {
    return (
        <Grid item sm={6} xs={12} display="flex" flexDirection="column" {...props}>
            <Paragraph variant="h5" fontFamily="AvR" mb="10px">{props.def1}</Paragraph>
            <Paragraph color="white" fontFamily="AvU" my="0px">{props.def2}</Paragraph>
        </Grid>
      );
}
 
export default Doubletxt;