import { Box, Container, Grid } from '@mui/material';
import React from 'react'
import Regtxt from '../../Regtxt';
import Paragraph from '../../Paragraph';
import LockerMenu from '../../LockerMenu';
import BlueButton from '../../BlueButton'; 
import styled from '@emotion/styled'; 
import MainBg from '../../../../assets/Images/mainbg.mp4'; // Import your video file
import mainBgMobile from '../../../../assets/Images/mainBgMobile.mp4'; // Import your video file
import { InputField } from '../../../Styles/style';


import Marquee from "react-fast-marquee";
const HeaderDesign = styled.div`
  min-height: 100vh;
  position: relative;
  z-index: 2;
  /* scroll-snap-align:start; */
  /* background: url(${MainBg}); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  /* border-image: fill 0 linear-gradient(transparent 60%,#000 95%); */
  @media(max-width: 900px){
    min-height: 100vh;
  }
  
`
const Home = () => { 


  return (  
    <>


    <HeaderDesign id="home">
      <Box sx={{display:{xs:"none",sm:"block"}}}>

      <video src={MainBg} loop muted autoPlay style={{
        width:"100%",
        minHeight:"100vh",
        position:"absolute",
        bottom:0,
        right:0,
        objectFit:"cover",
        zIndex:-1
      }} />
      </Box>
      <Box sx={{display:{xs:"block",sm:"none"}}}>

      <video src={mainBgMobile} loop muted autoPlay style={{
        width:"100%",
        minHeight:"100vh",
        position:"absolute",
        bottom:0,
        right:0,
        objectFit:"contain",
        zIndex:-1
      }} />
      </Box>
    <LockerMenu />
      <Container maxWidth="xl"  sx={{ minHeight:{xs:"calc(110vh)",sm:"calc(100vh)"} ,display:"flex", flexDirection:"column", justifyContent:{xs:"start",sm:"center"}, paddingTop:{xs:"100px",md:"2%"}, paddingBottom:{xs:"25%",md:"2%"}}}>
      <Grid container >
        <Grid item xs={12} sm={10} md={8} lg={6} data-aos="fade-right">

            <Regtxt fs599="33px" my="0px">Tokenizing <br />  <span style={{ fontSize:"clamp(2.375rem, 2.5vw + 1.875rem, 4.875rem)",color:"#0066FF"}}>Real Estate <br /> </span>For On-Chain Finance</Regtxt>
            <Box display="flex" flexDirection="column"  justifyContent="center" alignItems="start" gap="10px">  
              <Paragraph padding="10px 0"  sx={{maxWidth:"570px"}}>We're transforming real estate investment. Our platform seamlessly connects traditional assets with blockchain technology, unlocking a world of opportunity for investors and property owners.</Paragraph>  
              <Paragraph padding="5px 0 20px"  sx={{maxWidth:"500px"}}>Join us in revolutionizing asset management and trading on the blockchain.</Paragraph> 
            </Box>
            {/* <Box display="flex"  justifyContent="center" alignItems="start" gap="10px" sx={{maxWidth:"500px"}}>  
              <InputField placeholder='Email Address'/>
              <BlueButton href="">Join Waitlist</BlueButton>
            </Box> */}
        </Grid>
        </Grid>
      </Container>
    </HeaderDesign>
    </>
  )
}

export default Home;